import compareDesc from "date-fns/compareDesc"
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Color, Spacing } from "../Types/constants"
import Project from "./Project"
import Timeline from "./Timeline"

const sortDateEnd = (a: Project, b: Project) => {
  const dateA = a.date[1] ? new Date(a.date[1]) : new Date()
  const dateB = b.date[1] ? new Date(b.date[1]) : new Date()
  return compareDesc(dateA, dateB)
}

const $Projects = styled.section`
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  width: 100%;
  background-color: ${Color.light};
  color: ${Color.dark};
  box-shadow: 0 0 ${Spacing.large}px rgba(0, 0, 0, 0.2);
`

export const $ProjectsFrame = styled.div`
  overflow: visible;
  position: relative;
  width: 100%;
  max-width: 640px;
  margin: auto;
  padding: ${Spacing.huge}px ${Spacing.base}px;
`

interface ProjectsProps {
  projects: Project[]
  viewport: Viewport
}

const Projects: FunctionComponent<ProjectsProps> = ({ projects, viewport }) => {
  const [activeEntityId, setActiveEntityId] = useState<number | null>(null)
  const [projectsHeight, setProjectsHeight] = useState(100)
  const refProjectsContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const offsetHeight = refProjectsContainer.current?.offsetHeight||0
    setProjectsHeight(offsetHeight)
  }, [viewport])

  return (
    <$Projects>
      <$ProjectsFrame>
        <div style={{ display: "flex" }}>
          <Timeline
            projects={projects}
            projectsHeight={projectsHeight}
            setActiveEntityId={setActiveEntityId}
            activeEntityId={activeEntityId}
          />

          <div style={{ marginBottom: "auto" }} ref={refProjectsContainer}>
            {projects.sort(sortDateEnd).map(project => {
              const active = project.id === activeEntityId
              const inactive = !!activeEntityId && !active

              return (
                <Project
                  key={project.company.title + project.position.join("-")}
                  project={project}
                  inactive={inactive}
                  active={active}
                  setActiveEntityId={setActiveEntityId}
                />
              )
            })}
          </div>
        </div>
      </$ProjectsFrame>
    </$Projects>
  )
}

export default Projects
