import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import React, { FunctionComponent } from "react";

import Icon from "./Icon/Icon.component";

export const formatDate = (d: string) => format(new Date(d), "MM / yyyy");

const Time: FunctionComponent<{ date: string[] }> = ({ date }) => {
  const [from, to] = date.map(formatDate);
  const dateA = new Date(date[0]);
  const dateB = date[1] ? new Date(date[1]) : new Date();
  const duration = formatDistance(dateA, dateB);

  return (
    <>
      <Icon icon={faClock} size="small" />
      {date.length === 1 && "since "}
      {from}
      {to && ` – ${to}`} ({duration})
    </>
  );
};

export default Time;
