import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import React, { FunctionComponent } from "react"
import { Spacing } from "../../Types/constants"
import { $SVG } from "./Icon.styles"

const Icon: FunctionComponent<{
  icon: IconDefinition;
  size: keyof typeof Spacing;
}> = ({ icon, size }) => {
  const [width, height, , , path] = icon.icon

  return (
    <$SVG
      viewBox={`0 0 ${width} ${height}`}
      width={Spacing[size]}
      height={Spacing[size]}
    >
      <path d={path as string} />
    </$SVG>
  )
}

export default Icon
