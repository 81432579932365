import React from "react";
import useViewport from "../../Hooks/useViewport";
import projects from "../../projects.json";
import Footer from "../Footer";
import Header from "../Header";
import Intro from "../Intro";
import Projects from "../Projects";
import { $App, $Main } from "./CV.styles";

const CV = () => {
  const viewport = useViewport();
  const { width, height, tablet } = viewport;

  return (
    <$App id="App" width={width} height={height}>
      <Header tablet={tablet} />

      <$Main>
        <Intro />
        {/* TODO: Add groups to data instead of manual slicing */}
        <Projects projects={projects.slice(0, 5)} viewport={viewport} />
        <Projects projects={projects.slice(5, Infinity)} viewport={viewport} />
        <Footer />
      </$Main>
    </$App>
  );
};

export default CV;
