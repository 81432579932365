import styled from "styled-components";

interface $AppProps {
  width: number;
  height: number;
}

export const $App = styled.div<$AppProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const $Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;
