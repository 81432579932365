import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { FontSize, Spacing } from '../Types/constants'
import Icon from './Icon/Icon.component'
import Time from './Time'

const $Status = styled.header`
  display: flex;
  flex-wrap: wrap;
`
const $StatusItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.tiny}px;
  font-size: ${FontSize.small};
  font-family: sans-serif;
  letter-spacing: 0.5px;
  flex-wrap: wrap;

  &:not(:last-of-type) {
    margin-right: ${Spacing.small}px;
  }
`

interface StatusProps {
  company: any
  date: any
  faBuilding: any
  faMapMarkerAlt: any
}

const Status: FunctionComponent<StatusProps> = ({
  company,
  date,
  faBuilding,
  faMapMarkerAlt
}) => {
  return (
    <$Status>
      <$StatusItem>
        <Time date={date} />
      </$StatusItem>
      <$StatusItem>
        <Icon icon={faBuilding} size="small" /> {company.title} {company.form}
      </$StatusItem>
      <$StatusItem>
        <Icon icon={faMapMarkerAlt} size="small" />
        {company.location.join(', ')}
      </$StatusItem>
    </$Status>
  )
}

export default Status
