import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import CV from "./CV";
import Preview from "./Preview";

const Router: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cv" element={<CV />} />
      <Route path="/preview" element={<Preview />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
