import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Color, Spacing, FontSize } from '../Types/constants'
import me from '../user.json'
import { $ContactLink } from './Header/Header.component'
import Icon from './Icon/Icon.component'

const $Footer = styled.footer`
  position: relative;
  width: 100%;
  background-color: ${Color.light};
  color: ${Color.dark};
  flex-shrink: 0;
  box-shadow: 0 0 ${Spacing.large}px rgba(0, 0, 0, 0.2);
  font-size: ${FontSize.base};
  padding: ${Spacing.huge}px 0;
`

const $FooterFrame = styled.div`
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  padding: 0 ${Spacing.base}px;
`

const $FooterActions = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  line-height: ${Spacing.large}px;

  &:not(:last-of-type) {
    margin-right: ${Spacing.large}px;
  }
`

const Footer: FunctionComponent<{}> = () => {
  return (
    <$Footer>
      <$FooterFrame>
        <$FooterActions>
          <p>
            {me.firstName} {me.lastName}
          </p>
          <p>{me.street}</p>
          <p>
            {me.postalCode} {me.city}
          </p>
        </$FooterActions>
        <$FooterActions>
          <$ContactLink href={`tel:${me.phone}`}>
            <Icon icon={faMobileAlt} size="small" />
            {me.phone}
          </$ContactLink>
          <$ContactLink href={`mailto:${me.eMail}`}>
            <Icon icon={faEnvelope} size="small" />
            {me.eMail}
          </$ContactLink>
          <$ContactLink href={me.linkedIn}>
            <Icon icon={faLinkedin} size="small" />
            LinkedIn
          </$ContactLink>
        </$FooterActions>
      </$FooterFrame>
    </$Footer>
  )
}

export default Footer
