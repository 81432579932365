import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Color, FontSize, Spacing } from '../../Types/constants'
import me from '../../user.json'
import Icon from '../Icon/Icon.component'
import IconLink from '../IconLink'
import Logo from '../Logo'

const $Header = styled.header`
  z-index: 1;
  width: 100%;
  height: ${Spacing.touch}px;
  background-color: ${Color.light};
  color: ${Color.primary};
  flex-shrink: 0;
  font-size: ${FontSize.base};
  box-shadow: 0 0 ${Spacing.large}px rgba(0, 0, 0, 0.2);
`

const $HeaderFrame = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 ${Spacing.base}px;
`

export const $Contact = styled.div<{ tablet: boolean }>`
  display: flex;
  flex-direction: ${({ tablet }) => (tablet ? 'column' : 'row')};
  justify-content: center;
  align-items: ${({ tablet }) => (tablet ? 'flex-start' : 'center')};
  height: ${Spacing.touch}px;
  color: ${Color.dark};
`

export const $ContactLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.2s ease;
  color: inherit;
  line-height: ${Spacing.large}px;

  &:hover {
    color: ${Color.primary};
  }
`

const Header: FunctionComponent<{ tablet: boolean }> = ({ tablet }) => {
  const mail = `mailto:${me.eMail}`
  const tel = `tel:${me.phone}`

  return (
    <$Header>
      <$HeaderFrame>
        <Logo width={160} color="primary" />
        <$Contact tablet={tablet}>
          {tablet ? (
            <$ContactLink href={mail}>
              <Icon icon={faEnvelope} size="small" />
              {me.eMail}
            </$ContactLink>
          ) : (
            <IconLink icon={faEnvelope} to={mail} size="base" />
          )}
          {tablet ? (
            <$ContactLink href={tel}>
              <Icon icon={faMobileAlt} size="small" />
              {me.phone}
            </$ContactLink>
          ) : (
            <IconLink icon={faMobileAlt} to={tel} size="base" />
          )}
        </$Contact>
      </$HeaderFrame>
    </$Header>
  )
}

export default Header
