import React, { FC } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { formatDate } from "../Time";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingVertical: "20mm",
  },
  section: {
    marginLeft: "20mm",
    marginRight: "50mm",
    marginBottom: "10mm",
  },
  meta: {
    fontSize: 10,
    marginBottom: 5,
  },
  title: {
    fontSize: 12,
    marginBottom: 5,
  },
  description: {
    fontSize: 10,
    marginBottom: 5,
  },
});

// Create Document Component
const PDF: FC<{ projects: Project[] }> = ({ projects }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {projects.map((project) => {
        return (
          <View style={styles.section} wrap={false}>
            <Text style={styles.meta}>
              {project.date.map(formatDate).join(" - ")}
            </Text>
            <Text style={styles.meta}>
              {project.company.title} {project.company.form}
            </Text>

            <Text style={styles.meta}>
              {project.company.location.join(", ")}
            </Text>

            <Text style={styles.title}>{project.position}</Text>
            <Text style={styles.description}>{project.description}</Text>
          </View>
        );
      })}
    </Page>
  </Document>
);

export default PDF;
