import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Color, Spacing } from "../Types/constants";

const $Logo = styled.a`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: ${Spacing.touch}px;
`;

const Logo: FunctionComponent<{ width: number; color: keyof typeof Color }> = ({
  width = 200,
  color = "black",
}) => {
  return (
    <$Logo href="./">
      <svg
        id="logo"
        viewBox={[0, 0, 200, 38.6].join(" ")}
        width={width}
        fill={Color[color]}
      >
        <g>
          <g transform="matrix(1,0,0,1,10.4,10.7)" fill={Color.primary}>
            <path d="M5,16.8C5,18.5 4.6,19.7 3.9,20.6C3.2,21.4 2.1,22.1 0.6,22.6L0,20.9C0.8,20.6 1.3,20.3 1.7,19.9C2.1,19.6 2.3,19.1 2.5,18.6C2.6,18.1 2.7,17.5 2.7,16.7L2.7,2.2L5,2.2L5,16.8Z" />
            <path d="M18.2,7.8C19.2,9 19.7,10.7 19.7,12.7C19.7,14 19.5,15.2 19,16.3C18.5,17.3 17.9,18.1 17,18.7C16.1,19.3 15.1,19.6 13.9,19.6C12.1,19.6 10.7,19 9.6,17.8C8.6,16.6 8.1,14.9 8.1,12.9C8.1,11.6 8.3,10.4 8.8,9.3C9.3,8.3 9.9,7.5 10.8,6.9C11.7,6.3 12.7,6 13.9,6C15.8,5.9 17.2,6.5 18.2,7.8ZM10.6,12.7C10.6,16 11.7,17.6 13.9,17.6C16.1,17.6 17.2,15.9 17.2,12.6C17.2,9.3 16.1,7.7 13.9,7.7C11.7,7.8 10.6,9.4 10.6,12.7Z" />
            <path d="M31.8,7C32.5,7.7 32.8,8.7 32.8,9.9L32.8,19.2L30.5,19.2L30.5,10.2C30.5,9.3 30.3,8.6 30,8.3C29.6,7.9 29.1,7.7 28.5,7.7C27.8,7.7 27.2,7.9 26.7,8.3C26.2,8.7 25.7,9.3 25.2,10L25.2,19.2L22.9,19.2L22.9,1L25.2,0.8L25.2,8C26.2,6.6 27.5,5.9 29,5.9C30.2,5.9 31.1,6.3 31.8,7Z" />
            <path d="M45.7,17.3C45.9,17.6 46.2,17.7 46.5,17.9L46,19.5C45.3,19.4 44.8,19.2 44.4,18.9C44,18.6 43.7,18.1 43.5,17.5C42.6,18.8 41.3,19.4 39.6,19.4C38.3,19.4 37.3,19 36.6,18.3C35.9,17.6 35.5,16.6 35.5,15.5C35.5,14.1 36,13.1 37,12.3C38,11.6 39.4,11.2 41.2,11.2L43.2,11.2L43.2,10.2C43.2,9.3 43,8.6 42.5,8.2C42.1,7.8 41.4,7.6 40.5,7.6C39.5,7.6 38.4,7.8 37,8.3L36.4,6.7C38,6.1 39.5,5.8 40.9,5.8C42.4,5.8 43.6,6.2 44.3,6.9C45.1,7.6 45.4,8.7 45.4,10.1L45.4,16C45.4,16.7 45.5,17.1 45.7,17.3ZM43.2,15.8L43.2,12.8L41.5,12.8C39.1,12.8 37.9,13.7 37.9,15.5C37.9,16.3 38.1,16.9 38.5,17.2C38.9,17.6 39.4,17.8 40.2,17.8C41.4,17.8 42.4,17.1 43.2,15.8Z" />
            <path d="M58.4,7C59.1,7.7 59.4,8.7 59.4,9.9L59.4,19.2L57.1,19.2L57.1,10.2C57.1,9.3 56.9,8.6 56.6,8.3C56.3,7.9 55.7,7.7 55.1,7.7C54.4,7.7 53.8,7.9 53.3,8.3C52.8,8.7 52.3,9.3 51.8,10L51.8,19.2L49.5,19.2L49.5,6.2L51.4,6.2L51.6,8.1C52.1,7.4 52.6,6.9 53.3,6.5C54,6.1 54.7,5.9 55.6,5.9C56.8,5.9 57.7,6.3 58.4,7Z" />
            <path d="M72.2,7C72.9,7.7 73.2,8.7 73.2,9.9L73.2,19.2L70.9,19.2L70.9,10.2C70.9,9.3 70.7,8.6 70.4,8.3C70.1,7.9 69.5,7.7 68.9,7.7C68.2,7.7 67.6,7.9 67.1,8.3C66.6,8.7 66.1,9.3 65.6,10L65.6,19.2L63.3,19.2L63.3,6.2L65.2,6.2L65.4,8.1C65.9,7.4 66.4,6.9 67.1,6.5C67.8,6.1 68.5,5.9 69.4,5.9C70.6,5.9 71.5,6.3 72.2,7Z" />
            <path d="M86.9,13.5L78.6,13.5C78.7,14.9 79.1,16 79.7,16.7C80.3,17.4 81.1,17.7 82.1,17.7C82.7,17.7 83.3,17.6 83.8,17.4C84.3,17.2 84.9,16.9 85.5,16.5L86.5,17.9C85.1,19 83.6,19.5 82,19.5C80.2,19.5 78.8,18.9 77.8,17.7C76.8,16.5 76.3,14.9 76.3,12.8C76.3,11.5 76.5,10.3 77,9.2C77.4,8.2 78.1,7.3 78.9,6.8C79.7,6.2 80.7,5.9 81.8,5.9C83.5,5.9 84.8,6.5 85.8,7.6C86.7,8.7 87.2,10.3 87.2,12.3C86.9,12.7 86.9,13.1 86.9,13.5ZM84.7,11.7C84.7,10.4 84.4,9.4 83.9,8.8C83.4,8.1 82.6,7.8 81.6,7.8C79.7,7.8 78.7,9.2 78.6,11.9L84.7,11.9L84.7,11.7Z" />
            <path d="M95.9,6.3C96.6,6.5 97.3,6.9 97.9,7.4L96.9,8.8C96.3,8.4 95.7,8.1 95.2,8C94.7,7.9 94.1,7.7 93.5,7.7C92.8,7.7 92.2,7.9 91.8,8.2C91.4,8.5 91.2,8.9 91.2,9.4C91.2,9.9 91.4,10.3 91.8,10.6C92.2,10.9 92.9,11.2 94,11.5C95.4,11.9 96.5,12.4 97.2,13C97.9,13.6 98.3,14.5 98.3,15.6C98.3,16.9 97.8,17.9 96.8,18.5C95.8,19.1 94.6,19.5 93.2,19.5C91.2,19.5 89.6,18.9 88.3,17.8L89.5,16.4C90.6,17.2 91.8,17.7 93.1,17.7C94,17.7 94.6,17.5 95.1,17.2C95.6,16.8 95.9,16.4 95.9,15.8C95.9,15.4 95.8,15 95.6,14.7C95.4,14.4 95.1,14.2 94.7,14C94.3,13.8 93.6,13.6 92.8,13.3C91.4,12.9 90.4,12.5 89.8,11.8C89.2,11.1 88.9,10.4 88.9,9.5C88.9,8.8 89.1,8.2 89.5,7.7C89.9,7.2 90.5,6.7 91.2,6.4C91.9,6.1 92.7,5.9 93.6,5.9C94.5,5.9 95.2,6.1 95.9,6.3Z" />
            <path d="M114,2.4C114.8,2.7 115.6,3.2 116.3,3.9L115,5.3C114.4,4.8 113.7,4.4 113.1,4.2C112.5,4 111.8,3.8 111.1,3.8C110.2,3.8 109.5,4 108.9,4.4C108.3,4.8 108,5.4 108,6.2C108,6.7 108.1,7.1 108.3,7.4C108.5,7.7 108.9,8 109.4,8.3C109.9,8.6 110.7,8.9 111.7,9.2C112.8,9.5 113.7,9.9 114.4,10.3C115.1,10.7 115.7,11.2 116.1,11.9C116.5,12.6 116.8,13.4 116.8,14.4C116.8,15.4 116.6,16.3 116.1,17C115.6,17.8 114.9,18.3 114,18.8C113.1,19.2 112,19.4 110.8,19.4C108.5,19.4 106.5,18.7 105,17.2L106.3,15.7C107,16.3 107.7,16.7 108.4,17C109.1,17.3 109.9,17.4 110.8,17.4C111.9,17.4 112.7,17.1 113.4,16.6C114.1,16.1 114.4,15.3 114.4,14.3C114.4,13.7 114.3,13.3 114.1,12.9C113.9,12.5 113.5,12.2 113,11.9C112.5,11.6 111.7,11.3 110.7,11C109,10.5 107.7,9.8 106.9,9.1C106.1,8.4 105.7,7.4 105.7,6.1C105.7,5.2 105.9,4.5 106.4,3.8C106.9,3.1 107.5,2.6 108.3,2.2C109.1,1.8 110,1.6 111.1,1.6C112.3,1.9 113.2,2.1 114,2.4Z" />
            <path d="M121.9,0.4C122.2,0.7 122.3,1.1 122.3,1.5C122.3,1.9 122.2,2.3 121.9,2.6C121.6,2.9 121.2,3 120.7,3C120.2,3 119.9,2.9 119.6,2.6C119.3,2.3 119.2,1.9 119.2,1.5C119.2,1.1 119.3,0.7 119.6,0.4C119.9,0.1 120.3,-0 120.7,-0C121.2,0 121.6,0.1 121.9,0.4ZM121.9,19.2L119.6,19.2L119.6,6.2L121.9,6.2L121.9,19.2Z" />
            <path d="M135.7,13.5L127.4,13.5C127.5,14.9 127.9,16 128.5,16.7C129.1,17.4 129.9,17.7 130.9,17.7C131.5,17.7 132.1,17.6 132.6,17.4C133.1,17.2 133.7,16.9 134.2,16.5L135.2,17.9C133.8,19 132.3,19.5 130.7,19.5C128.9,19.5 127.5,18.9 126.5,17.7C125.5,16.5 125,14.9 125,12.8C125,11.5 125.2,10.3 125.7,9.2C126.1,8.2 126.8,7.3 127.6,6.8C128.4,6.2 129.4,5.9 130.5,5.9C132.2,5.9 133.5,6.5 134.5,7.6C135.4,8.7 135.9,10.3 135.9,12.3C135.8,12.7 135.7,13.1 135.7,13.5ZM133.5,11.7C133.5,10.4 133.2,9.4 132.7,8.8C132.2,8.1 131.4,7.8 130.4,7.8C128.5,7.8 127.5,9.2 127.4,11.9L133.5,11.9L133.5,11.7Z" />
            <path d="M148.9,1L148.9,19.2L146.9,19.2L146.7,17.4C146.3,18.1 145.7,18.6 145.1,18.9C144.5,19.3 143.7,19.5 143,19.5C141.5,19.5 140.3,18.9 139.4,17.7C138.5,16.5 138.1,14.9 138.1,12.8C138.1,11.5 138.3,10.3 138.7,9.3C139.1,8.3 139.7,7.5 140.5,6.9C141.3,6.3 142.2,6 143.2,6C144.5,6 145.7,6.5 146.7,7.6L146.7,0.7L148.9,1ZM145.2,17.2C145.7,16.9 146.2,16.4 146.6,15.8L146.6,9.4C146.2,8.8 145.7,8.4 145.2,8.1C144.7,7.8 144.2,7.7 143.6,7.7C142.6,7.7 141.8,8.1 141.3,9C140.8,9.8 140.5,11.1 140.5,12.7C140.5,14.4 140.8,15.6 141.3,16.4C141.8,17.2 142.5,17.6 143.5,17.6C144.2,17.7 144.8,17.5 145.2,17.2Z" />
            <path d="M153.6,18.8C153.1,18.3 152.9,17.6 152.9,16.7L152.9,1L155.2,0.7L155.2,16.6C155.2,17 155.3,17.2 155.4,17.4C155.5,17.6 155.7,17.6 156,17.6C156.3,17.6 156.6,17.6 156.8,17.5L157.4,19.1C156.9,19.4 156.3,19.5 155.6,19.5C154.7,19.5 154.1,19.3 153.6,18.8Z" />
            <path d="M169.1,13.5L160.8,13.5C160.9,14.9 161.3,16 161.9,16.7C162.5,17.4 163.3,17.7 164.3,17.7C164.9,17.7 165.5,17.6 166,17.4C166.5,17.2 167.1,16.9 167.6,16.5L168.6,17.9C167.2,19 165.7,19.5 164.1,19.5C162.3,19.5 160.9,18.9 159.9,17.7C158.9,16.5 158.4,14.9 158.4,12.8C158.4,11.5 158.6,10.3 159.1,9.2C159.5,8.2 160.2,7.3 161,6.8C161.8,6.2 162.8,5.9 163.9,5.9C165.6,5.9 166.9,6.5 167.9,7.6C168.8,8.7 169.3,10.3 169.3,12.3C169.2,12.7 169.1,13.1 169.1,13.5ZM166.9,11.7C166.9,10.4 166.6,9.4 166.1,8.8C165.6,8.2 164.8,7.8 163.8,7.8C161.9,7.8 160.9,9.2 160.8,11.9L166.9,11.9L166.9,11.7Z" />
            <path d="M179.2,6.1L178.8,8.3C178.4,8.2 178,8.2 177.7,8.2C176.9,8.2 176.2,8.5 175.8,9.1C175.3,9.7 174.9,10.6 174.6,11.9L174.6,19.3L172.3,19.3L172.3,6.3L174.2,6.3L174.4,8.9C174.7,7.9 175.2,7.2 175.8,6.7C176.4,6.2 177.1,6 177.9,6C178.4,5.9 178.8,6 179.2,6.1Z" />
          </g>
        </g>
      </svg>
    </$Logo>
  );
};

export default Logo;
