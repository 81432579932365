import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import PDF from "../PDF/PDF.component";
import projects from "../../projects.json";

const Preview = () => {
  return (
    <PDFViewer style={{ width: "100vw", height: "100vh" }}>
      <PDF projects={projects} />
    </PDFViewer>
  );
};

export default Preview;
