import React, { FunctionComponent } from "react";
import styled from "styled-components";
import about from "../about.json";
import useViewport from "../Hooks/useViewport";
import { Color, Spacing } from "../Types/constants";

const $Intro = styled.section`
  position: relative;
  width: 100%;
  background-color: ${Color.white};
  color: ${Color.dark};
  flex-shrink: 0;
`;

const $IntroFrame = styled.div<{ tablet: boolean }>`
  position: relative;
  display: flex;
  flex-direction: ${({ tablet }) => (tablet ? "row" : "column")};
  width: 100%;
  max-width: 640px;
  margin: auto;
  padding: ${Spacing.huge}px ${Spacing.base}px 0 ${Spacing.base}px;
`;

const $IntroContent = styled.div<{ tablet: boolean }>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 3;
  width: ${({ tablet }) => (tablet ? 0 : "100%")};
  margin-bottom: ${({ tablet }) => (tablet ? Spacing.touch : 0)}px;
`;

const $IntroTitle = styled.h1`
  font-family: "Noto Serif", serif;
  color: ${Color.primary};
  font-size: 32px;
  margin-bottom: ${Spacing.large}px;
`;

const $IntroImageWrapper = styled.div<{ tablet: boolean }>`
  flex-grow: 2;
  width: ${({ tablet }) => (tablet ? 0 : "100%")};
  margin: 0 ${({ tablet }) => (tablet ? -Spacing.touch : 0)}px;
`;

const $IntroPortrait = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
`;

const Intro: FunctionComponent<{}> = () => {
  const { tablet } = useViewport();

  return (
    <$Intro id="intro">
      <$IntroFrame tablet={tablet}>
        <$IntroContent tablet={tablet}>
          <$IntroTitle>Hello!</$IntroTitle>
          <p>{about.intro}</p>
        </$IntroContent>
        <$IntroImageWrapper tablet={tablet}>
          <$IntroPortrait width="256" src="portrait.jpg" />
        </$IntroImageWrapper>
      </$IntroFrame>
    </$Intro>
  );
};

export default Intro;
