import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import styled from 'styled-components'
import { Color, Spacing, FontSize } from '../../Types/constants'
import Status from '../Status'
import Tags from '../Tags'

const $Entity = styled.div<{ active: boolean; inactive: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 0 ${Spacing.touch}px 0;
  width: 100%;
  max-width: 640px;
  flex-grow: 1;
  transform: scale(${({ active }) => (active ? 1.02 : 1)});
  transform-origin: 50% 50%;
  transition: all 0.2s ease;
  color: ${({ active, inactive }) =>
    active ? Color.primary : inactive ? Color.gray : Color.dark};
`

const $EntityTitle = styled.h3`
  font-size: ${FontSize.large};
  margin: 0 0 ${Spacing.tiny}px 0;
  font-family: 'Noto Serif', serif;
`

const $EntityDescription = styled.p`
  margin: 0 0 ${Spacing.small}px 0;
  font-family: 'Noto Serif', serif;
  font-size: ${FontSize.base};
`

function join(arr: string[]) {
  return arr.reduce((str, item, index, array) => {
    const separator = index === array.length - 1 ? ' & ' : ', '
    return `${str}${separator}${item}`
  })
}

const Project: FunctionComponent<{
  project: Project
  active: boolean
  inactive: boolean
  setActiveEntityId: Dispatch<SetStateAction<number|null>>
}> = ({ project, active, inactive, setActiveEntityId }) => {
  const { id, date, position, company, tags, tasks, description } = project

  // console.log("active", active, inactive)
  return (
    <$Entity
      className="project"
      active={active}
      inactive={inactive}
      onMouseEnter={setActiveEntityId.bind(null, id)}
      onTouchStart={setActiveEntityId.bind(null, id)}
      onMouseLeave={setActiveEntityId.bind(null, null)}
      onTouchEnd={setActiveEntityId.bind(null, null)}
    >
      <Status
        date={date}
        faBuilding={faBuilding}
        faMapMarkerAlt={faMapMarkerAlt}
        company={company}
      />

      <$EntityTitle>{join(position)}</$EntityTitle>
      {description && <$EntityDescription>{description}</$EntityDescription>}
      {tasks && <Tags tags={tasks} theme="primary" inactive={inactive} />}
      {tags && <Tags tags={tags} theme="secondary" inactive={inactive} />}
    </$Entity>
  )
}

export default Project
