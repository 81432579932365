import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { Color, Spacing } from "../Types/constants"
import Icon from "./Icon/Icon.component"

const $IconLink = styled.a`
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${Spacing.huge}px;
  height: ${Spacing.huge}px;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  transition: color 0.2s ease;

  &:hover {
    color: ${Color.primary};
  }
`

interface IconLinkProps {
  icon: any
  size: keyof typeof Spacing
  to: string
}

const IconLink: FunctionComponent<IconLinkProps> = ({ icon, size, to }) => {
  return (
    <$IconLink href={to} target="_blank">
      <Icon icon={icon} size={size} />
    </$IconLink>
  )
}

export default IconLink
