import compareDesc from "date-fns/compareDesc";
import max from "date-fns/max";
import min from "date-fns/min";
import React, {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { Color, Spacing, FontSize } from "../Types/constants";

const $Timeline = styled.div`
  margin-right: ${Spacing.base}px;
`;

const $TimelineBar = styled.rect<{ active: boolean; inactive: boolean }>`
  fill: ${({ active }) => (active ? Color.primary : Color.white)};
  stroke: ${({ inactive }) => (inactive ? Color.gray : Color.primary)};
  stroke-width: 1;
  transition: fill 0.2s ease, stroke 0.2s ease;
`;

const offset = 0;
const spacingSVG = Spacing.base - 2;

type ViewBox = [number, number, number, number];

interface Rect {
  x: number;
  y: {
    start: number;
    end: number;
  };
  date: {
    start: number;
    end: number;
  };
}

function getViewHeight(viewBox: ViewBox) {
  return viewBox[3] - 2 * offset;
}

interface GetYParams {
  date: string;
  dateMin: number;
  dateMax: number;
  viewBox: ViewBox;
}

function getY({ date, dateMin, dateMax, viewBox }: GetYParams) {
  const viewHeight = getViewHeight(viewBox);
  const timestamp = new Date(date).getTime();

  return (
    viewHeight -
    ((timestamp - dateMin) / (dateMax - dateMin)) * viewHeight +
    offset
  );
}

interface GetRectsParams {
  datePairs: number[][];
  dateMin: number;
  dateMax: number;
  viewBox: ViewBox;
}

function getRects({ datePairs, dateMin, dateMax, viewBox }: GetRectsParams) {
  const viewHeight = getViewHeight(viewBox);

  const rects = datePairs.reduce((prevRects, [start, end = dateMax], index) => {
    const y = {
      end:
        viewHeight -
        ((end - dateMin) / (dateMax - dateMin)) * viewHeight +
        offset,
      start:
        viewHeight -
        ((start - dateMin) / (dateMax - dateMin)) * viewHeight +
        offset,
    };

    const x = prevRects.reduce((_x, prevRect) => {
      const overlap = prevRect.y.start > y.end && _x === prevRect.x;
      if (overlap) {
        _x += Spacing.base - 2 + Spacing.tiny;
      }
      return _x;
    }, 0);

    const currRect = {
      date: {
        end,
        start,
      },
      x,
      y,
    };
    return [...prevRects, currRect];
  }, [] as Rect[]);

  return rects;
}

interface TimelineProps {
  projects: Project[];
  projectsHeight: number;
  dateMin?: number;
  dateMax?: number;
  setActiveEntityId: Dispatch<SetStateAction<number | null>>;
  activeEntityId: number | null;
}

const $Date = styled.text`
  fill: ${Color.primary};
  font-size: ${FontSize.small};
  font-weight: 700;
`;
const Timeline: FunctionComponent<TimelineProps> = ({
  projects,
  projectsHeight,
  dateMin,
  dateMax,
  setActiveEntityId,
  activeEntityId,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const viewBox: ViewBox = useMemo(
    () => [0, 0, Spacing.touch, projectsHeight],
    [projectsHeight]
  );

  const datePairs = projects
    .map(({ date }) => {
      if (date.length === 1) date[1] = new Date().toString();

      return date.map((d) => new Date(d).getTime());
    })
    .sort((a, b) => compareDesc(a[1], b[1]));

  const datesAll = datePairs
    .reduce((prev, curr) => [...prev, ...curr], [])
    .map((d) => new Date(d))
    .sort(compareDesc);

  if (!dateMin) {
    dateMin = min(datesAll).getTime();
  }

  if (!dateMax) {
    dateMax = max(datesAll).getTime();
  }

  const years = [];
  const yearMin = new Date(dateMin).getFullYear();
  const yearMax = new Date(dateMax).getFullYear();

  for (let y = yearMax; y > yearMin; y--) {
    years.push(y.toString());
  }

  const [rects, setRects] = useState(
    getRects({ datePairs, dateMin, dateMax, viewBox })
  );

  useEffect(() => {
    // @ts-ignore: nulls
    const _rects = getRects({ datePairs, dateMin, dateMax, viewBox });
    setRects(_rects);
  }, [dateMax, dateMin, datePairs, projectsHeight, viewBox]);

  return (
    <$Timeline>
      <svg
        style={{
          flexShrink: 0,
          overflow: "visible",
        }}
        viewBox={viewBox.join(" ")}
        width={viewBox[2]}
        height={viewBox[3]}
      >
        {years.map((date) => {
          // @ts-ignore: nulls
          const y = getY({ date, dateMin, dateMax, viewBox });

          return (
            <Fragment key={date}>
              <rect
                style={{
                  fill: Color.primary,
                }}
                x={0}
                y={y}
                width={spacingSVG + Spacing.tiny + spacingSVG}
                height={1}
              />
              <$Date
                x={spacingSVG + Spacing.tiny + spacingSVG + Spacing.tiny}
                y={y}
                dy="5px"
              >
                {date.slice(-2)}
              </$Date>
            </Fragment>
          );
        })}
        {rects.map(({ x, y }, index) => {
          const active = projects[index].id === activeEntityId;
          const inactive = Boolean(activeEntityId && !active);
          return (
            <Fragment key={index}>
              <$TimelineBar
                active={active}
                inactive={inactive}
                x={x}
                y={y.end}
                rx={Spacing.nano}
                ry={Spacing.nano}
                width={spacingSVG}
                height={y.start - y.end}
                onMouseEnter={setActiveEntityId.bind(null, projects[index].id)}
                onTouchStart={setActiveEntityId.bind(null, projects[index].id)}
                onMouseLeave={setActiveEntityId.bind(null, null)}
                onTouchEnd={setActiveEntityId.bind(null, null)}
              />
            </Fragment>
          );
        })}
      </svg>
    </$Timeline>
  );
};

export default Timeline;
