import Logo from "../Logo";

const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Logo width={160} color="primary" />
    </div>
  );
};

export default Home;
