export enum Spacing {
  nano = 2,
  tiny = 6,
  small = 12,
  base = 18,
  large = 24,
  huge = 44,
  touch = 64
}

export enum Color {
  primary = '#283E55',
  black = '#000000',
  dark = '#222222',
  gray = '#999999',
  light = '#FAFAFA',
  white = '#FFFFFF'
}

export enum Breakpoint {
  mobile = 320,
  tablet = 540,
  desktop = 760
}

export enum FontSize {
  small = '12px',
  base = '14px',
  large = '18px'
}
