import debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { Breakpoint } from "../Types/constants";

function getViewport(): Viewport {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const tablet = width >= Breakpoint.tablet;
  const desktop = width >= Breakpoint.desktop;

  return {
    desktop,
    height,
    tablet,
    width,
  };
}

export default function useViewport() {
  const [viewport, setViewport] = useState(getViewport());
  const handleResize = debounce(() => setViewport(getViewport()), 500);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return viewport;
}
