import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Color, Spacing, FontSize } from '../Types/constants'

type Theme = 'primary' | 'secondary'

const $Tags = styled.div`
  margin: 0 0 ${Spacing.tiny}px 0;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
`

const getColorBackground = (theme: Theme, inactive: boolean) => {
  switch (theme) {
    case 'primary':
      return inactive ? Color.gray : Color.primary
    case 'secondary':
      return Color.white
    default:
      return inactive ? Color.gray : Color.primary
  }
}

const getColor = (theme: Theme, inactive: boolean) => {
  switch (theme) {
    case 'primary':
      return Color.white
    case 'secondary':
      return inactive ? Color.gray : Color.primary
    default:
      return Color.white
  }
}

const getColorBorder = (theme: Theme, inactive: boolean) => {
  switch (theme) {
    case 'primary':
      return inactive ? Color.gray : 'transparent'
    case 'secondary':
      return inactive ? Color.gray : Color.primary
    default:
      return 'transparent'
  }
}

const $Tag = styled.div<{ theme: Theme; inactive: boolean }>`
  font-family: sans-serif;
  font-size: ${FontSize.small};
  font-weight: 600;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${Spacing.tiny}px ${Spacing.tiny}px 0;
  padding: 0 ${Spacing.tiny}px;
  border: 1px solid ${({ theme, inactive }) => getColorBorder(theme, inactive)};
  color: ${({ theme, inactive }) => getColor(theme, inactive)};
  background-color: ${({ theme, inactive }) =>
    getColorBackground(theme, inactive)};
  height: ${Spacing.base}px;
  border-radius: ${Spacing.nano}px;
`

interface TagsProps {
  tags: string[]
  theme?: Theme
  inactive?: boolean
}

const Tags: FunctionComponent<TagsProps> = ({
  tags,
  theme = 'default',
  inactive = false
}) => {
  return (
    <$Tags>
      {tags.sort().map(tag => (
        <$Tag key={tag} theme={theme} inactive={inactive}>
          {tag}
        </$Tag>
      ))}
    </$Tags>
  )
}

export default Tags
